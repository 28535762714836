exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-media-kit-js": () => import("./../../../src/pages/about/media-kit.js" /* webpackChunkName: "component---src-pages-about-media-kit-js" */),
  "component---src-pages-about-technology-js": () => import("./../../../src/pages/about/technology.js" /* webpackChunkName: "component---src-pages-about-technology-js" */),
  "component---src-pages-about-why-salesbinder-js": () => import("./../../../src/pages/about/why-salesbinder.js" /* webpackChunkName: "component---src-pages-about-why-salesbinder-js" */),
  "component---src-pages-api-getting-started-authentication-js": () => import("./../../../src/pages/api/getting-started/authentication.js" /* webpackChunkName: "component---src-pages-api-getting-started-authentication-js" */),
  "component---src-pages-api-getting-started-rate-limiting-js": () => import("./../../../src/pages/api/getting-started/rate-limiting.js" /* webpackChunkName: "component---src-pages-api-getting-started-rate-limiting-js" */),
  "component---src-pages-api-index-js": () => import("./../../../src/pages/api/index.js" /* webpackChunkName: "component---src-pages-api-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-ios-js": () => import("./../../../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kb-index-js": () => import("./../../../src/pages/kb/index.js" /* webpackChunkName: "component---src-pages-kb-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tour-account-management-js": () => import("./../../../src/pages/tour/account-management.js" /* webpackChunkName: "component---src-pages-tour-account-management-js" */),
  "component---src-pages-tour-currency-exchange-js": () => import("./../../../src/pages/tour/currency-exchange.js" /* webpackChunkName: "component---src-pages-tour-currency-exchange-js" */),
  "component---src-pages-tour-inventory-barcode-scanning-js": () => import("./../../../src/pages/tour/inventory-barcode-scanning.js" /* webpackChunkName: "component---src-pages-tour-inventory-barcode-scanning-js" */),
  "component---src-pages-tour-inventory-item-variations-js": () => import("./../../../src/pages/tour/inventory-item-variations.js" /* webpackChunkName: "component---src-pages-tour-inventory-item-variations-js" */),
  "component---src-pages-tour-inventory-kitting-bundling-js": () => import("./../../../src/pages/tour/inventory-kitting-bundling.js" /* webpackChunkName: "component---src-pages-tour-inventory-kitting-bundling-js" */),
  "component---src-pages-tour-inventory-multiple-locations-zones-js": () => import("./../../../src/pages/tour/inventory-multiple-locations-zones.js" /* webpackChunkName: "component---src-pages-tour-inventory-multiple-locations-zones-js" */),
  "component---src-pages-tour-online-inventory-management-js": () => import("./../../../src/pages/tour/online-inventory-management.js" /* webpackChunkName: "component---src-pages-tour-online-inventory-management-js" */),
  "component---src-pages-tour-orders-billing-invoices-estimates-js": () => import("./../../../src/pages/tour/orders-billing-invoices-estimates.js" /* webpackChunkName: "component---src-pages-tour-orders-billing-invoices-estimates-js" */),
  "component---src-pages-tour-purchase-orders-js": () => import("./../../../src/pages/tour/purchase-orders.js" /* webpackChunkName: "component---src-pages-tour-purchase-orders-js" */),
  "component---src-pages-tour-reporting-js": () => import("./../../../src/pages/tour/reporting.js" /* webpackChunkName: "component---src-pages-tour-reporting-js" */),
  "component---src-pages-tour-shipment-tracking-js": () => import("./../../../src/pages/tour/shipment-tracking.js" /* webpackChunkName: "component---src-pages-tour-shipment-tracking-js" */),
  "component---src-pages-tour-user-permissions-js": () => import("./../../../src/pages/tour/user-permissions.js" /* webpackChunkName: "component---src-pages-tour-user-permissions-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-endpoint-js": () => import("./../../../src/templates/endpoint.js" /* webpackChunkName: "component---src-templates-endpoint-js" */),
  "component---src-templates-industry-index-js": () => import("./../../../src/templates/industry-index.js" /* webpackChunkName: "component---src-templates-industry-index-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-method-js": () => import("./../../../src/templates/method.js" /* webpackChunkName: "component---src-templates-method-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

